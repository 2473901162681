import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui

import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Box,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { db } from '../App';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'organization', label: 'Institución', alignRight: false },
  { id: 'group', label: 'Total de intentos', alignRight: false },
  { id: 'age', label: 'Puntaje Global', alignRight: false },
  { id: 'nd', label: 'ND', alignRight: false },
  { id: 'ne', label: 'NE', alignRight: false },
  { id: 'nt', label: 'NT', alignRight: false },
  { id: 'nc', label: 'NC', alignRight: false },
  { id: 'score', label: 'Nota', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: null, label: null, alignRight: null },
];

// ----------------------------------------------------------------------

function descendingComparator(
  a: { [x: string]: number },
  b: { [x: string]: number },
  orderBy: string
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: any[],
  comparator: { (a: any, b: any): number; (arg0: any, arg1: any): any },
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user: { name: string }) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PlayersPage() {
  const [open, setOpen] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [currentUser, setCurrentUser] = useState(null);

  const [data, setData] = useState([]);

  const [userTracker, setUserTracker] = useState([]);

  const [quizTrackerData, setQuizTrackerData] = useState([]);

  const fetchData = async () => {
    const data: any = await getDocs(collection(db, 'users'));
    setData(data.docs.map((doc: any) => doc.data()));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenMenu = (event, user: any) => {
    setOpen(event.currentTarget);
    setCurrentUser(user);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (
    event: any,
    property: React.SetStateAction<string>
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: { target: { checked: any } }) => {
    if (event.target.checked) {
      const newSelected = USERLIST.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: any
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] | ((prevState: never[]) => never[]) = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const onlyPlayers = data.filter(
    (user: { role: string }) => user.role === 'PLAYER'
  );
  const filteredUsers = applySortFilter(
    onlyPlayers,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;

  const activateUser = async (user: any): void => {
    const docRef = doc(db, 'users', user.id);
    await updateDoc(docRef, {
      status: 'ACTIVE',
    })
      .then(() => {
        alert('Document successfully updated!');
        fetchData();
      })
      .catch((error) => {
        alert('Error updating document: ', error);
      });
  };

  useEffect(() => {
    fetchHistoryTrackerData();
  }, [currentUser]);

  const fetchHistoryTrackerData = async () => {
    const tracker: any = await getDocs(collection(db, 'tracker'));
    const trackerData = tracker.docs.map((doc: any) => doc.data());

    // filter data by user
    const userTracker = trackerData.filter(
      (track: { id: string }) => track.id === currentUser?.id
    );
    setUserTracker(userTracker);

    // filter userTracker data by quizId and count attempts and points
    const quizTracker = userTracker.filter(
      (track: { quizId: string }) => track.quizId === 'descartes'
    );
    const quizTracker2 = userTracker.filter(
      (track: { quizId: string }) => track.quizId === 'einstein'
    );
    const quizTracker3 = userTracker.filter(
      (track: { quizId: string }) => track.quizId === 'tharp'
    );
    const quizTracker4 = userTracker.filter(
      (track: { quizId: string }) => track.quizId === 'clodomiro'
    );

    const quizTrackerData = {
      quiz1: {
        attempts: quizTracker.reduce((a: any, b: any) => a + b.attempts, 0),
        points: quizTracker.reduce((a: any, b: any) => a + b.points, 0),
      },
      quiz2: {
        attempts: quizTracker2.reduce((a: any, b: any) => a + b.attempts, 0),
        points: quizTracker2.reduce((a: any, b: any) => a + b.points, 0),
      },
      quiz3: {
        attempts: quizTracker3.reduce((a: any, b: any) => a + b.attempts, 0),
        points: quizTracker3.reduce((a: any, b: any) => a + b.points, 0),
      },
      quiz4: {
        attempts: quizTracker4.reduce((a: any, b: any) => a + b.attempts, 0),
        points: quizTracker4.reduce((a: any, b: any) => a + b.points, 0),
      },
    };
    setQuizTrackerData(quizTrackerData);
  };

  return (
    <>
      <Helmet>
        <title>Users | Sagalab System Manager</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Jugadores
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      (row: {
                        id: any;
                        name: any;
                        email: any;
                        attempts: any;
                        points: any;
                        scoreDescartes: any;
                        scoreEinstein: any;
                        scoreTharp: any;
                        scoreClodomiro: any;
                        globalScore: any;
                        username: any;
                        role: any;
                        status: any;
                        company: any;
                        avatarUrl: any;
                        isVerified: any;
                      }) => {
                        const {
                          avatarUrl,
                          id,
                          name,
                          username,
                          company,
                          status,
                          points,
                          scoreDescartes,
                          scoreEinstein,
                          scoreTharp,
                          scoreClodomiro,
                          globalScore,
                          attempts,
                          email,
                        } = row;
                        const selectedUser = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedUser}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar alt={name} src={avatarUrl} />
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{email}</TableCell>

                            <TableCell align="left">{company}</TableCell>

                            <TableCell align="left">{attempts}</TableCell>

                            <TableCell align="left">{points || 0}</TableCell>

                            <TableCell align="left">
                              {scoreDescartes
                                ? parseInt(scoreDescartes)
                                : 'N/A'}
                            </TableCell>
                            <TableCell align="left">
                              {scoreEinstein ? parseInt(scoreEinstein) : 'N/A'}
                            </TableCell>
                            <TableCell align="left">
                              {scoreTharp ? parseInt(scoreTharp) : 'N/A'}
                            </TableCell>
                            <TableCell align="left">
                              {scoreClodomiro
                                ? parseInt(scoreClodomiro)
                                : 'N/A'}
                            </TableCell>
                            <TableCell align="left">
                              {globalScore ? parseInt(globalScore) : 'N/A'}
                            </TableCell>

                            <TableCell align="left">
                              <Label
                                color={
                                  (status === 'PENDING' && 'warning') ||
                                  (status === 'INACTIVE' && 'error') ||
                                  'success'
                                }
                              >
                                {sentenceCase(status)}
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) => handleOpenMenu(e, row)}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {currentUser && (
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            {/*currentUser!.status === 'ACTIVE' && (
            <MenuItem>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
            </MenuItem>
          )*/}

            {currentUser!.status === 'ACTIVE' && (
              <MenuItem
                onClick={() => {
                  fetchHistoryTrackerData();
                  setOpenModal(true);
                }}
              >
                <Iconify icon={'eva:file-text-outline'} sx={{ mr: 2 }} />
                Ver historial
              </MenuItem>
            )}

            {currentUser!.status === 'PENDING' ? (
              <MenuItem onClick={() => activateUser(currentUser)}>
                <Iconify icon={'eva:paper-plane-outline'} sx={{ mr: 2 }} />
                Activar
              </MenuItem>
            ) : null}

            {/*currentUser!.status === 'ACTIVE' && (
          <MenuItem onClick={() => {alert(`Ban ${currentUser!.name}`)}} sx={{ color: 'orange' }} >
            <Iconify icon={'eva:slash-outline'} sx={{ mr: 2 }} />
            Ban
          </MenuItem>
        )

          <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Eliminar
          </MenuItem>
          */}
          </Popover>
        )}
      </Container>
      <Modal open={openModal} onClose={() => setOpenModal(false)} center>
        <Box sx={{ width: 350 }}>
          <Typography variant="h3">Historial de Intentos</Typography>
          <Typography variant="body1">
            Player:{' '}
            {currentUser?.name +
              (currentUser && currentUser.last_name
                ? ' ' + currentUser?.last_name
                : '')}
          </Typography>
          <Typography variant="body1">
            Institución: {currentUser?.company}
          </Typography>

          <Box my={5} />
          {quizTrackerData && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Quiz</TableCell>
                  <TableCell>Fallos</TableCell>
                  <TableCell>Aciertos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Descartes</TableCell>
                  <TableCell>{quizTrackerData?.quiz1?.attempts}</TableCell>
                  <TableCell>{quizTrackerData?.quiz1?.points}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Einstein</TableCell>
                  <TableCell>{quizTrackerData?.quiz2?.attempts}</TableCell>
                  <TableCell>{quizTrackerData?.quiz2?.points}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tharp</TableCell>
                  <TableCell>{quizTrackerData?.quiz3?.attempts}</TableCell>
                  <TableCell>{quizTrackerData?.quiz3?.points}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Clodomiro</TableCell>
                  <TableCell>{quizTrackerData?.quiz4?.attempts}</TableCell>
                  <TableCell>{quizTrackerData?.quiz4?.points}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Box>
      </Modal>
    </>
  );
}
