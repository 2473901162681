import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { db, auth } from "./../../../App";
import { collection, getDocs } from "firebase/firestore";

import {  signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [users, setUsers] = useState([]);
  
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const data: any = await getDocs(collection(db, "users"));
    setUsers(data.docs.map((doc: any) => doc.data()));
  };

  const handleClick = async () => {
    try {
        await signInWithEmailAndPassword(auth, email, password).then(async (userCredential: any) => {
            const user = userCredential.user;
            const data: any = await getDocs(collection(db, "users"));
            const results = data.docs.map((doc: any) => doc.data());
            const _user = results.find((u: any) => u.email === user?.email);
            localStorage.setItem('manager-token', _user.id);
            navigate('/');
        }).catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
        })

        // actions.users.getAuthUser();
    } catch (error) {
        alert('error');
    }
  };

  const handlePasswordKeyDown = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };
  

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" onChange={(e) => setEmail(e.target.value)} label="Correo electrónico" />

        <TextField
          name="password"
          label="Contraseña"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handlePasswordKeyDown}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>


      <LoadingButton sx={{mt:3}} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Iniciar Sesión
      </LoadingButton>
    </>
  );
}
