import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------
/*
name', label: 'Nombre', alignRight: false },
  { id: 'username', label: 'Usuario', alignRight: false },
  { id: 'organization', label: 'Organización', alignRight: false },
  { id: 'group', label: 'Grupo', alignRight: false },
  { id: 'age', label: 'Edad', alignRight: false },
  { id: 'status', label: 'Porcentaje de avance', alignRight: true },
];
*/
const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  username: faker.random.word(),
  organization: faker.random.words(),
  group: faker.random.alphaNumeric(),
  age: faker.random.numeric(),
  status: faker.random.numeric()
}));

export default users;
