import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Divider,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Checkbox,
  TableContainer,
  IconButton,
  TablePagination,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppWebsiteVisits,
  AppWidgetSummary,
  AppCurrentSubject,
} from '../sections/@dashboard/app';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import Descartes from './../assets/characters/thumb-descartes.png';
import Einstein from './../assets/characters/thumb-einstein.png';
import Tharp from './../assets/characters/thumb-marie.png';
import Clodomiro from './../assets/characters/thumb-clodomiro.png';
import React, { useEffect, useState } from 'react';
import { db } from '../App';
import { collection, getDocs, query, where } from 'firebase/firestore';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'gender', label: 'Sexo', alignRight: false },
  { id: 'province', label: 'Provincia', alignRight: false },
  { id: 'group', label: 'Total de intentos', alignRight: false },
  { id: 'age', label: 'Puntaje Global', alignRight: false },
  { id: null, label: null, alignRight: null },
];

// ----------------------------------------------------------------------

function descendingComparator(
  a: { [x: string]: number },
  b: { [x: string]: number },
  orderBy: string
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: any[],
  comparator: { (a: any, b: any): number; (arg0: any, arg1: any): any },
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user: { name: string }) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ReportsPage() {
  const theme = useTheme();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [data, setData] = useState([]);

  const [provinceIdx, setProvinceIdx] = useState(-1);

  const [organizations, setOrganizations] = useState([]);

  const [organizationIdentifier, setOrganizationIdentifier] = useState();

  /*const [gender, setGender] = useState(-1);*/

  const [sex, setSex] = useState(-1);

  const [age, setAge] = useState(-1);

  const [percentageDescartes, setPercentageDescartes] = useState(0);

  const [percentageEinstein, setPercentageEinstein] = useState(0);

  const [percentageTharp, setPercentageTharp] = useState(0);

  const [percentageClodomiro, setPercentageClodomiro] = useState(0);

  const [descartesByMonth, setDescartesByMonth] = React.useState([]);
  const [einsteinByMonth, setEinsteinByMonth] = React.useState([]);
  const [tharpByMonth, setTharpByMonth] = React.useState([]);
  const [clodomiroByMonth, setClodomiroByMonth] = React.useState([]);

  const getRecordsByMonth = (records: any) => {
    // Create an array of all the months of the year
    //const months = Array.from({ length: 12 }, (_, index) => index + 1);

    // Filter records that have points equal to 1
    const filteredRecords = records.filter((record) => record.points === 1);

    // Group filtered records by month using dateMonth

    // create array of 12 empty arrays but set 0 as a value for each array value
    const descartesByMonth = Array.from({ length: 12 }, () => 0);
    const einsteinByMonth = Array.from({ length: 12 }, () => 0);
    const tharpByMonth = Array.from({ length: 12 }, () => 0);
    const clodomiroByMonth = Array.from({ length: 12 }, () => 0);

    for (const record of filteredRecords) {
      const month = record.dateMonth;
      // if record.quizId is descartes then add 1 to totalDescartes
      if (record.quizId === 'descartes') {
        descartesByMonth[month] = descartesByMonth[month] + 1;
      }
      if (record.quizId === 'einstein') {
        einsteinByMonth[month] = einsteinByMonth[month] + 1;
      }
      if (record.quizId === 'tharp') {
        tharpByMonth[month] = tharpByMonth[month] + 1;
      }
      if (record.quizId === 'clodomiro') {
        clodomiroByMonth[month] = clodomiroByMonth[month] + 1;
      }
    }

    setDescartesByMonth(descartesByMonth);
    setEinsteinByMonth(einsteinByMonth);
    setTharpByMonth(tharpByMonth);
    setClodomiroByMonth(clodomiroByMonth);
  };

  const fetchData = async (
    updateGender: any,
    updateProvince: any,
    updateSex: any,
    updateAge: any
  ) => {
    const orgsQuery = query(
      collection(db, 'organizations'),
      where('name', '!=', 'Saga Gigantes')
    );
    const organizationsRes = await getDocs(orgsQuery);
    const orgs: any = organizationsRes.docs.map((doc: any) => doc.data());

    setOrganizations(orgs);

    const usersQuery = query(
      collection(db, 'users'),
      where('role', '==', 'PLAYER')
    );
    const usersResponse = await getDocs(usersQuery);
    const userArray = usersResponse.docs.map((doc: any) => doc.data());

    let filteredUsers: any;

    if (updateGender) {
      filteredUsers = userArray.filter(
        (user: any) => user.gender === updateGender
      );
    } else if (updateProvince) {
      filteredUsers = userArray.filter(
        (user: any) => user.province === updateProvince
      );
    } else if (updateSex) {
      filteredUsers = userArray.filter((user: any) => user.sex === updateSex);
    } else if (updateAge) {
      filteredUsers = userArray.filter((user: any) => user.age === updateAge);
    } else {
      filteredUsers = userArray.filter(
        (user: any) => user.company === orgs[organizationIdentifier]?.name
      );
    }

    setData(filteredUsers);

    const tracker: any = await getDocs(collection(db, 'tracker'));
    const trackerData = tracker.docs.map((doc: any) => doc.data());
    const filteredTrackerData = trackerData.filter(
      (tracker: { userId: string }) =>
        filteredUsers.some((user: { id: string }) => user.id === tracker.id)
    );
    const _data = filteredTrackerData
      .filter((tracker: { points: number }) => tracker.points === 1)
      .sort(
        (a: { date: string }, b: { date: string }) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

    getRecordsByMonth(_data);

    const dashboardData = {
      totalUsers: filteredUsers.length,
      totalDescartes: filteredUsers.filter((user: any) => user.descartes)
        .length,
      totalEinstein: filteredUsers.filter((user: any) => user.einstein).length,
      totalTharp: filteredUsers.filter((user: any) => user.tharp).length,
      totalClodomiro: filteredUsers.filter((user: any) => user.clodomiro)
        .length,
    };

    const {
      totalUsers,
      totalDescartes,
      totalEinstein,
      totalTharp,
      totalClodomiro,
    } = dashboardData;

    const percentageDescartes = (totalDescartes / totalUsers) * 100;
    const percentageEinstein = (totalEinstein / totalUsers) * 100;
    const percentageTharp = (totalTharp / totalUsers) * 100;
    const percentageClodomiro = (totalClodomiro / totalUsers) * 100;

    setPercentageDescartes(percentageDescartes);
    setPercentageEinstein(percentageEinstein);
    setPercentageTharp(percentageTharp);
    setPercentageClodomiro(percentageClodomiro);
  };

  const fetchAllData = async (orgId) => {
    const orgsQuery = query(
      collection(db, 'organizations'),
      where('name', '!=', 'Saga Gigantes')
    );
    const organizationsRes = await getDocs(orgsQuery);
    const orgs: any = organizationsRes.docs.map((doc: any) => doc.data());

    setOrganizations(orgs);

    const usersQuery = query(
      collection(db, 'users'),
      where('role', '==', 'PLAYER')
    );
    const usersResponse = await getDocs(usersQuery);
    const userArray: any = usersResponse.docs.map((doc: any) => doc.data());

    if (orgId) {
      const filteredUsers = userArray.filter(
        (user: any) => user.company === orgs[orgId]?.name
      );
      setData(filteredUsers);
    }

    setData(userArray);

    const tracker: any = await getDocs(collection(db, 'tracker'));
    const trackerData = tracker.docs.map((doc: any) => doc.data());
    const filteredTrackerData = trackerData.filter(
      (tracker: { userId: string }) =>
        userArray.some((user: { id: string }) => user.id === tracker.id)
    );
    const _data = filteredTrackerData
      .filter((tracker: { points: number }) => tracker.points === 1)
      .sort(
        (a: { date: string }, b: { date: string }) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

    getRecordsByMonth(_data);

    const dashboardData = {
      totalUsers: userArray.length,
      totalDescartes: userArray.filter((user: any) => user.descartes).length,
      totalEinstein: userArray.filter((user: any) => user.einstein).length,
      totalTharp: userArray.filter((user: any) => user.tharp).length,
      totalClodomiro: userArray.filter((user: any) => user.clodomiro).length,
    };

    const {
      totalUsers,
      totalDescartes,
      totalEinstein,
      totalTharp,
      totalClodomiro,
    } = dashboardData;

    const percentageDescartes = (totalDescartes / totalUsers) * 100;
    const percentageEinstein = (totalEinstein / totalUsers) * 100;
    const percentageTharp = (totalTharp / totalUsers) * 100;
    const percentageClodomiro = (totalClodomiro / totalUsers) * 100;

    setPercentageDescartes(percentageDescartes);
    setPercentageEinstein(percentageEinstein);
    setPercentageTharp(percentageTharp);
    setPercentageClodomiro(percentageClodomiro);
  };

  useEffect(() => {
    fetchData(false, false, false, false);
  }, [organizationIdentifier]);

  const handleOpenMenu = (event: any, user: any) => {
    // setCurrentUser(user);
  };

  const handleRequestSort = (
    event: any,
    property: React.SetStateAction<string>
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: { target: { checked: any } }) => {
    if (event.target.checked) {
      const newSelecteds: any = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: any
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] | ((prevState: never[]) => never[]) = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>Reports | Sagalab System Manager</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Reportes
        </Typography>
        <Typography variant="paragraph">
          Utilice los siguientes campos de selección para filtrar los
          resultados.
        </Typography>

        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={3} lg={3}>
            <Typography variant="paragraph">Provincia:</Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={provinceIdx}
                onChange={(e) => {
                  const value = e.target.value;
                  setProvinceIdx(value);
                  if (value === 'Todas') {
                    fetchAllData(false);
                  } else {
                    fetchData(false, value, false, false);
                  }
                }}
              >
                <MenuItem selected value={-1}>
                  Todas
                </MenuItem>
                <MenuItem value={'Alajuela'}>Alajuela</MenuItem>
                <MenuItem value={'Puntarenas'}>Puntarenas</MenuItem>
                <MenuItem value={'Cartago'}>Cartago</MenuItem>
                <MenuItem value={'Guanacaste'}>Guanacaste</MenuItem>
                <MenuItem value={'Heredia'}>Heredia</MenuItem>
                <MenuItem value={'San José'}>San José</MenuItem>
                <MenuItem value={'Limón'}>Limón</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3} lg={3}>
            <Typography variant="paragraph">Organización:</Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={organizations[organizationIdentifier]?.id || -1}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === 'Todas') {
                    fetchAllData(false);
                  } else {
                    setOrganizationIdentifier(
                      organizations.findIndex((org) => org.id === value)
                    );
                    fetchData(false, false, false, false);
                  }
                }}
              >
                <MenuItem selected value={-1}>
                  Todas
                </MenuItem>
                {organizations.map((organization: any, idx: number) => (
                  <MenuItem key={idx} value={organization.id}>
                    {organization.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/*<Grid item xs={2.4} lg={2.4}>
            <Typography variant="paragraph">Género:</Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                onChange={(e) => {
                  const value: any = e.target.value;
                  setGender(value);
                  if (value === 'Todas') {
                    fetchAllData(false);
                  } else {
                    fetchData(value, false, false, false);
                  }
                }}
              >
                <MenuItem selected value={-1}>
                  Todos
                </MenuItem>
                <MenuItem value={'Heterosexual'}>Heterosexual</MenuItem>
                <MenuItem value={'Lesbiana'}>Lesbiana</MenuItem>
                <MenuItem value={'Gay'}>Gay</MenuItem>
                <MenuItem value={'Bisexual'}>Bisexual</MenuItem>
                <MenuItem value={'Transgénero'}>Transgénero</MenuItem>
                <MenuItem value={'Queer'}>Queer</MenuItem>
                <MenuItem value={'Intersexual'}>Intersexual</MenuItem>
                <MenuItem value={'Asexual'}>Asexual</MenuItem>
                <MenuItem value={'NoBinario'}>No Binario</MenuItem>
                <MenuItem value={'Otro'}>Otro</MenuItem>
                <MenuItem value={'NoDecir'}>Prefiero no decir</MenuItem>
              </Select>
            </FormControl>
              </Grid>*/}

          <Grid item xs={3} lg={3}>
            <Typography variant="paragraph">Sexo:</Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sex}
                onChange={(e) => {
                  const value: any = e.target.value;
                  setSex(value);
                  if (value === 'Todas') {
                    fetchAllData(false);
                  } else {
                    fetchData(false, false, value, false);
                  }
                }}
              >
                <MenuItem value={-1}>Todos</MenuItem>
                <MenuItem value={'Masculino'}>Masculino</MenuItem>
                <MenuItem value={'Femenino'}>Femenino</MenuItem>
                <MenuItem value={'Otro'}>Otro</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3} lg={3}>
            <Typography variant="paragraph">Edad:</Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                onChange={(e) => {
                  const value: any = e.target.value;
                  setAge(value);
                  if (value === 'Todas') {
                    fetchAllData(false);
                  } else {
                    fetchData(false, false, false, value);
                  }
                }}
              >
                <MenuItem selected value={-1}>
                  --Selecciona una--
                </MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ borderStyle: 'dashed', mt: 2, mb: 2 }} />

        {
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="René Descartes"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={percentageDescartes}
                color="success"
                icon={Descartes}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Albert Einstein"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={percentageEinstein}
                color="info"
                icon={Einstein}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Marie Tharp"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={percentageTharp}
                color="warning"
                icon={Tharp}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Clodomiro Picado"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={percentageClodomiro}
                color="error"
                icon={Clodomiro}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AppWebsiteVisits
                title="Visitas por Laboratorio"
                subheader="Ordenas por día"
                chartLabels={[
                  '01/01/2023',
                  '02/01/2023',
                  '03/01/2023',
                  '04/01/2023',
                  '05/01/2023',
                  '06/01/2023',
                  '07/01/2023',
                  '08/01/2023',
                  '09/01/2023',
                  '10/01/2023',
                  '11/01/2023',
                ]}
                chartData={[
                  {
                    name: 'René Descartes',
                    type: 'line',
                    fill: 'solid',
                    data: descartesByMonth,
                  },
                  {
                    name: 'Albert Einstein',
                    type: 'line',
                    fill: 'solid',
                    data: einsteinByMonth,
                  },
                  {
                    name: 'Marie Tharp',
                    type: 'line',
                    fill: 'solid',
                    data: tharpByMonth,
                  },
                  {
                    name: 'Clodomiro Picado',
                    type: 'line',
                    fill: 'solid',
                    data: clodomiroByMonth,
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentSubject
                title="Nota promedio de laboratorios"
                chartLabels={[
                  'Descartes',
                  'Einstein',
                  'Tharp',
                  'Clodomiro',
                  'Erastótenes',
                  'Curie',
                ]}
                chartData={[
                  {
                    name: 'Series 1',
                    data: [
                      percentageDescartes === 100 ? 99 : percentageDescartes,
                      percentageEinstein === 100 ? 99 : percentageEinstein,
                      percentageTharp === 100 ? 99 : percentageTharp,
                      percentageClodomiro === 100 ? 99 : percentageClodomiro,
                      0,
                      0,
                    ],
                  },
                ]}
                chartColors={[...Array(6)].map(
                  () => theme.palette.text.secondary
                )}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map(
                            (row: {
                              id: any;
                              name: any;
                              email: any;
                              attempts: any;
                              points: any;
                              username: any;
                              role: any;
                              status: any;
                              company: any;
                              avatarUrl: any;
                              isVerified: any;
                              gender: any;
                              province: any;
                            }) => {
                              const {
                                avatarUrl,
                                id,
                                name,
                                province,
                                points,
                                attempts,
                                email,
                                gender,
                              } = row;
                              const selectedUser =
                                selected.indexOf(name) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={selectedUser}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={selectedUser}
                                      onChange={(event) =>
                                        handleClick(event, name)
                                      }
                                    />
                                  </TableCell>

                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Avatar alt={name} src={avatarUrl} />
                                      <Typography variant="subtitle2" noWrap>
                                        {name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">{email}</TableCell>

                                  <TableCell align="left">{gender}</TableCell>

                                  <TableCell align="left">{province}</TableCell>

                                  <TableCell align="left">{attempts}</TableCell>

                                  <TableCell align="left">
                                    {points || 0}
                                  </TableCell>

                                  <TableCell align="right">
                                    <IconButton
                                      size="large"
                                      color="inherit"
                                      onClick={(e) => handleOpenMenu(e, row)}
                                    >
                                      <Iconify
                                        icon={'eva:more-vertical-fill'}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using
                                  complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={USERLIST.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        }
      </Container>
    </>
  );
}
