import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import PlayersPage from './pages/PlayersPage';
import TeamPage from './pages/TeamPage';
import ReportsPage from './pages/ReportsPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import React from 'react';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />, // Display the DashboardLayout as the main layout
      children: [
        { element: <DashboardAppPage />, index: true }, // Display the DashboardAppPage as the main page
        { path: 'users', element: <UserPage /> },
        { path: 'players', element: <PlayersPage /> },
        { path: 'team', element: <TeamPage /> },
        { path: 'reports', element: <ReportsPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'login', element: <LoginPage /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
