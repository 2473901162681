import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// sections
import {
  AppWebsiteVisits,
  AppWidgetSummary,
  AppCurrentSubject,
} from '../sections/@dashboard/app';
import Descartes from './../assets/characters/thumb-descartes.png';
import Einstein from './../assets/characters/thumb-einstein.png';
import Tharp from './../assets/characters/thumb-marie.png';
import Clodomiro from './../assets/characters/thumb-clodomiro.png';
import React, { useEffect } from 'react';
import { db } from '../App';
import {
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const [currentPlayers, setCurrentPlayers] = React.useState([]);

  const [totalUsers, setTotalUsers] = React.useState(0);

  const [totalDescartes, setTotalDescartes] = React.useState(0);
  const [totalEinstein, setTotalEinstein] = React.useState(0);
  const [totalTharp, setTotalTharp] = React.useState(0);
  const [totalClodomiro, setTotalClodomiro] = React.useState(0);

  const [descartesByMonth, setDescartesByMonth] = React.useState([]);
  const [einsteinByMonth, setEinsteinByMonth] = React.useState([]);
  const [tharpByMonth, setTharpByMonth] = React.useState([]);
  const [clodomiroByMonth, setClodomiroByMonth] = React.useState([]);

  const getRecordsByMonth = (records: any) => {
    // Create an array of all the months of the year
    //const months = Array.from({ length: 12 }, (_, index) => index + 1);

    // Filter records that have points equal to 1
    const filteredRecords = records.filter((record) => record.points === 1);

    // Group filtered records by month using dateMonth

    // create array of 12 empty arrays but set 0 as a value for each array value
    const descartesByMonth = Array.from({ length: 12 }, () => 0);
    const einsteinByMonth = Array.from({ length: 12 }, () => 0);
    const tharpByMonth = Array.from({ length: 12 }, () => 0);
    const clodomiroByMonth = Array.from({ length: 12 }, () => 0);

    for (const record of filteredRecords) {
      const month = record.dateMonth;
      // if record.quizId is descartes then add 1 to totalDescartes
      if (record.quizId === 'descartes') {
        descartesByMonth[month] = descartesByMonth[month] + 1;
      }
      if (record.quizId === 'einstein') {
        einsteinByMonth[month] = einsteinByMonth[month] + 1;
      }
      if (record.quizId === 'tharp') {
        tharpByMonth[month] = tharpByMonth[month] + 1;
      }
      if (record.quizId === 'clodomiro') {
        clodomiroByMonth[month] = clodomiroByMonth[month] + 1;
      }
    }

    setDescartesByMonth(descartesByMonth);
    setEinsteinByMonth(einsteinByMonth);
    setTharpByMonth(tharpByMonth);
    setClodomiroByMonth(clodomiroByMonth);
  };

  const fetchDashboardInfo = async () => {
    let usersQuery;
    let usersResponse;
    let _users;

    const ___data: any = await getDocs(collection(db, 'users'));
    const ___users = ___data.docs.map((doc: any) => doc.data());

    const user = ___users.find(
      (u: any) => u.id === localStorage.getItem('manager-token')
    );

    if (user?.role === 'ADMIN') {
      usersQuery = query(
        collection(db, 'users'),
        where('role', '==', 'PLAYER')
      );
      usersResponse = await getDocs(usersQuery);
      _users = usersResponse.docs.map((doc: any) => doc.data());
    } else {
      usersQuery = query(
        collection(db, 'users'),
        where('ownerId', '==', localStorage.getItem('manager-token'))
      );
      usersResponse = await getDocs(usersQuery);
      _users = usersResponse.docs.map((doc: any) => doc.data());
    }

    const tracker: any = await getDocs(collection(db, 'tracker'));
    const trackerData = tracker.docs.map((doc: any) => doc.data());
    const filteredTrackerData = trackerData.filter(
      (tracker: { userId: string }) =>
        _users.some((user: { id: string }) => user.id === tracker.id)
    );
    const _data = filteredTrackerData
      .filter((tracker: { points: number }) => tracker.points === 1)
      .sort(
        (a: { date: string }, b: { date: string }) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

    getRecordsByMonth(_data);

    const data = user?.role === 'OWNER' ? currentPlayers : _users;

    let totalDescartes = 0;
    let totalEinstein = 0;
    let totalTharp = 0;
    let totalClodomiro = 0;

    data?.map((user: any) => {
      if (user.descartes === true) {
        totalDescartes = totalDescartes + 1;
      }

      if (user.einstein === true) {
        totalEinstein = totalEinstein + 1;
      }

      if (user.tharp === true) {
        totalTharp = totalTharp + 1;
      }

      if (user.clodomiro === true) {
        totalClodomiro = totalClodomiro + 1;
      }
    });

    setTotalUsers(data!.length);

    setTotalDescartes((totalDescartes / data!.length) * 100);
    setTotalEinstein((totalEinstein / data!.length) * 100);
    setTotalTharp((totalTharp / data!.length) * 100);
    setTotalClodomiro((totalClodomiro / data!.length) * 100);
  };

  const fetchUser = async () => {
    const data: any = await getDocs(collection(db, 'users'));
    const users = data.docs.map((doc: any) => doc.data());

    const organizationsData: any = await getDocs(
      collection(db, 'organizations')
    );
    const organizations = organizationsData.docs.map((doc: any) => doc.data());

    const user = users.find(
      (u: any) => u.id === localStorage.getItem('manager-token')
    );
    const organization = organizations.find(
      (o: any) => o.id === user.organization
    );

    const token = localStorage.getItem('manager-token');
    const players = users.filter(
      (user: { ownerId: string; role: string }) => user.ownerId === token
    );
    setCurrentPlayers(players);

    setUser(user);
    setOrganization(organization);
  };

  useEffect(() => {
    fetchUser();
    fetchDashboardInfo();

    const token = localStorage.getItem('manager-token');
    if (!token) {
      window.location.href = '/login';
    }
  }, [totalDescartes, totalEinstein, totalTharp, totalClodomiro, totalUsers]);

  return (
    <>
      <Helmet>
        <title> Sagalab | System Manager </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Sagalab | Administrador de Sistema
        </Typography>

        {
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="René Descartes"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={totalDescartes ? totalDescartes : 0}
                color="success"
                icon={Descartes}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Albert Einstein"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={totalEinstein}
                color="info"
                icon={Einstein}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Marie Tharp"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={totalTharp}
                color="warning"
                icon={Tharp}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Clodomiro Picado"
                subtitle={'de los jugadores ya completaron este laboratorio'}
                total={totalClodomiro}
                color="error"
                icon={Clodomiro}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AppWebsiteVisits
                title="Completados por Laboratorio"
                subheader="Aqui puedes ver la cantidad de laboratorios completados por los jugadores"
                chartLabels={[
                  '01/01/2023',
                  '02/01/2023',
                  '03/01/2023',
                  '04/01/2023',
                  '05/01/2023',
                  '06/01/2023',
                  '07/01/2023',
                  '08/01/2023',
                  '09/01/2023',
                  '10/01/2023',
                  '11/01/2023',
                  '12/01/2023',
                ]}
                chartData={[
                  {
                    name: 'René Descartes',
                    type: 'line',
                    fill: 'solid',
                    data: descartesByMonth,
                  },
                  {
                    name: 'Albert Einstein',
                    type: 'line',
                    fill: 'solid',
                    data: einsteinByMonth,
                  },
                  {
                    name: 'Marie Tharp',
                    type: 'line',
                    fill: 'solid',
                    data: tharpByMonth,
                  },
                  {
                    name: 'Clodomiro Picado',
                    type: 'line',
                    fill: 'solid',
                    data: clodomiroByMonth,
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentSubject
                title="Nota promedio de laboratorios"
                chartLabels={[
                  'Descartes',
                  'Einstein',
                  'Tharp',
                  'Clodomiro',
                  'Erastótenes',
                  'Curie',
                ]}
                chartData={[
                  {
                    name: 'Series 1',
                    data: [
                      totalDescartes === 100 ? 99 : totalDescartes,
                      totalEinstein === 100 ? 99 : totalEinstein,
                      totalTharp === 100 ? 99 : totalTharp,
                      totalClodomiro === 100 ? 99 : totalClodomiro,
                      0,
                      0,
                    ],
                  },
                ]}
                chartColors={[...Array(6)].map(
                  () => theme.palette.text.secondary
                )}
              />
            </Grid>
          </Grid>
        }
      </Container>
    </>
  );
}
