import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../assets/logo-sagalab.svg';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import React from 'react';

import { db } from "./../../../App";
import { collection, getDocs } from "firebase/firestore";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

const temporaryImg = "https://firebasestorage.googleapis.com/v0/b/sagas-24491.appspot.com/o/images%2Fthumb-clodomiro.png?alt=media&token=d58c4dce-f0cc-477c-8ca8-6a1e69135ffc";

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const [user, setUser] = React.useState<any>({});
  const [organization, setOrganization] = React.useState<any>({});

  const isDesktop = useResponsive('up', 'lg');

  const fetchUser = async () => {
    const data: any = await getDocs(collection(db, "users"));
    const users = data.docs.map((doc: any) => doc.data());
    
    const organizationsData: any = await getDocs(collection(db, "organizations"));
    const organizations = organizationsData.docs.map((doc: any) => doc.data());

    const user = users.find((u: any) => u.id === localStorage.getItem('manager-token'));
    const organization = organizations.find((o: any) => o.id === user.organization);

    setUser(user);
    setOrganization(organization);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Box
          component="img"
          width={200}
          sx={{
            margin: "0 auto",
            paddingTop:2,
            paddingBottom:2
          }}
          src={Logo}
        />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={ ( user && user.avatarUrl ) ? user.avatarUrl : temporaryImg } alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                { ( user && user.name  )? user.name : "N/A" }
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                { ( user && user.role )? user.role : "" }
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} role={ ( user && user.role )? user.role : "" } />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          

          <Button href="/login" onClick={() => {
            localStorage.removeItem('manager-token');
            window.location.href = '/login';
          }}>
            Cerrar Sesión
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
