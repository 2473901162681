// component
import SvgColor from '../../../components/svg-color';
import React from 'react';

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Principal',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Organizaciónes',
    path: '/users',
    icon: icon('ic_user'),
  },
  {
    title: 'Jugadores',
    path: '/players',
    icon: icon('ic_user'),
  },
  {
    title: 'Mi Equipo',
    path: '/team',
    icon: icon('ic_user'),
  },
  {
    title: 'Reportes',
    path: '/reports',
    icon: icon('ic_user'),
  }
];

export default navConfig;
