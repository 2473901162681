import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Box,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import React from 'react';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { db } from "../App";
import { collection, getDocs, doc, addDoc, updateDoc } from "firebase/firestore";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'organization', label: 'Institución', alignRight: false },
  { id: 'group', label: 'Total de intentos', alignRight: false },
  { id: 'age', label: 'Puntaje Global', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: null, label: null, alignRight: null },
];

// ----------------------------------------------------------------------

function descendingComparator(a: { [x: string]: number; }, b: { [x: string]: number; }, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array: any[], comparator: { (a: any, b: any): number; (arg0: any, arg1: any): any; }, query: string) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user: { name: string; }) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TeamPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [currentUser, setCurrentUser] = useState(null);
  
  const [currentPlayers, setCurrentPlayers] = useState([]);

  const [data, setData] = useState([]);

  const [organizationsData, setOrganizationsData] = useState([]);

  const [openPlayers, setOpenPlayers] = useState(false);

  const [showGroupList, setShowGroupList] = useState(false);

  const [emailValue, setEmailValue] = useState('');

  const [emailList, setEmailList] = useState([]);

  const [user, setUser] = useState(null);

  const [userTracker, setUserTracker] = useState([]);
  
  const fetchData = async () => {
    const users: any = await getDocs(collection(db, "users"));
    const usersData = users.docs.map((doc: any) => doc.data());
    setData(usersData);
    
    // const organizationsData: any = await getDocs(collection(db, "organizations"));
    // setOrganizationsData(data.docs.map((doc: any) => doc.data()));

    const userId = localStorage.getItem('manager-token');
    const _user = usersData.find((user: { id: string; }) => user.id === userId);
    setUser(_user);

    const players = usersData.filter((user: { ownerId: string; role: string; }) => user.ownerId === _user?.id);
    setCurrentPlayers(players);
  };

  const fetchHistoryTrackerData = async () => {
    const tracker: any = await getDocs(collection(db, "tracker"));
    const trackerData = tracker.docs.map((doc: any) => doc.data());
    
    // filter data by user
    const userTracker = trackerData.filter((track: { id: string; }) => track.id === currentUser?.id);
    setUserTracker(userTracker);
  };
  
  useEffect(() => {
    fetchData();
    fetchHistoryTrackerData();
  }, [currentUser]);

  const handleOpenMenu = (event, user: any) => {
    setCurrentUser(user);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event: any, property: React.SetStateAction<string>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: { target: { checked: any; }; }) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] | ((prevState: never[]) => never[]) = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(currentPlayers, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const activateUser = async (user: any): void => {
    await updateDoc(doc(db, "users", user.id), {
      status: user.status === "ACTIVE" || user.status === "PENDING" ? "INACTIVE" : "ACTIVE",
    }).then(() => {
      fetchData();
    }).catch((error) => {
      alert("Error updating document: ", error);
    });
  };

  const findOrganization = (user: any) => {
    const organization = organizationsData.filter((organization: { id: string; }) => organization.id === user.id);
    return organization[0].company;
  };

  const capitalizeFirstLetter = (_string: string) => _string.charAt(0).toUpperCase() + _string.slice(1);

  const invitePlayer = async (user: any): void => {
    const addFirestoreRecord = async (record) => {
      const company = findOrganization(user);

      await addDoc(collection(db, "users"), {
        name: capitalizeFirstLetter(record.name),
        email: record.email,
        role: record.role,
        status: record.status,
        isVerified: record.isVerified,
        avatarUrl: record.avatarUrl,
        ownerId: user?.id,
        username: record.username,
        points: 0,
        attempts: 0,
        company,
      }).then((uRef) => {

        updateDoc(doc(db, "users", uRef.id), {
          id: uRef.id,
        }).then(() => {
          setOpenPlayers(false);
          setEmailValue('');
          setEmailList([]);
          fetchData();  
        }).catch((error) => {
          alert("Error updating document: ", error);
        });
        
      });
    };

    if (emailList.length > 0) {
      emailList.forEach((email: string) => {
        const name = email.split('@')[0];
        const user = {
          name: name,
          username: name.toLowerCase().replace(' ', '.'),
          email: email,
          role: "PLAYER",
          avatarUrl: "https://firebasestorage.googleapis.com/v0/b/sagalab-8f9f0.appspot.com/o/avatars%2Fdefault.png?alt=media&token=3b5b8b7a-7b9a-4b0a-8b0a-3a0a0a0a0a0a",
          status: "INACTIVE",
          isVerified: false,
        };
        addFirestoreRecord(user);
      });
    } else {
      alert('Agrega al menos un correo electrónico');
    }
  };

  const addEmail = () => {
    setEmailList([...emailList, emailValue]);
    setEmailValue('');
  };

  const updateEmailField = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmailValue(event.target.value || '');
  };

  const renderModal = () => {
    return ( 
      <Modal open={openPlayers} onClose={() => setOpenPlayers(false)} center>
        <Box sx={{ width: 350 }}>
          <Typography variant="h2">Historial de Intentos</Typography>
          <Typography variant="body1">Player: {currentUser?.name + " " + currentUser?.last_name}</Typography>
          <Typography variant="body1">Institución: {currentUser?.company}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Quiz</TableCell>
                <TableCell>Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userTracker.map((track: any) => (
                <TableRow>
                  <TableCell>{track.quizId}</TableCell>
                  <TableCell style={{ color: `${track.points > 0 ? 'green' : 'red'}` }}>
                    {track.points > 0 ? 'Point' : 'Attempt'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Modal>
    )
  };

  return (
    <>
      <Helmet>
        <title>Users | Sagalab System Manager</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {user && user.company}
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: { id: any; name: any; email: any; attempts: any; points: any; username: any; role: any; status: any; company: any; avatarUrl: any; isVerified: any; }) => {
                    const { avatarUrl, id, name, username, company, role, status, points, attempts, email } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        
                        <TableCell align="left">{company}</TableCell>

                        <TableCell align="left">{attempts}</TableCell>

                        <TableCell align="left">{points || 0}</TableCell>

                        <TableCell align="left">
                            <Label color={(status === 'PENDING' && 'warning') || (status === 'INACTIVE' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>              

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                        
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {currentUser && (
          <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >

          {currentUser!.status === 'ACTIVE' && (
            <MenuItem onClick={() => setOpenPlayers(true)} >
              <Iconify icon={'eva:file-text-outline'} sx={{ mr: 2 }} />
              History
            </MenuItem>
          )}

          <MenuItem onClick={() => activateUser(currentUser)} sx={{ color: 'orange' }} >
            <Iconify icon={'eva:paper-plane-outline'} sx={{ mr: 2}} />
            {currentUser!.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
        )}
      </Container>
      {currentUser && renderModal()}
    </>
  );
}
